var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, e) {
  exports = e();
}(window, function () {
  return function (t) {
    var e = {};

    function n(r) {
      if (e[r]) return e[r].exports;
      var o = e[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return t[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }

    return n.m = t, n.c = e, n.d = function (t, e, r) {
      n.o(t, e) || Object.defineProperty(t, e, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, n.t = function (t, e) {
      if (1 & e && (t = n(t)), 8 & e) return t;
      if (4 & e && "object" == typeof t && t && t.__esModule) return t;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: t
      }), 2 & e && "string" != typeof t) for (var o in t) n.d(r, o, function (e) {
        return t[e];
      }.bind(null, o));
      return r;
    }, n.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return n.d(e, "a", e), e;
    }, n.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, n.p = "/", n(n.s = 5);
  }([function (t, e, n) {
    var r = n(1);
    "string" == typeof r && (r = [[t.i, r, ""]]);
    var o = {
      hmr: !0,
      transform: void 0,
      insertInto: void 0
    };
    n(3)(r, o);
    r.locals && (t.exports = r.locals);
  }, function (t, e, n) {
    (t.exports = n(2)(!1)).push([t.i, ".inline-code {\n  background: rgba(250, 239, 240, 0.78);\n  color: #b44437;\n  padding: 3px 4px;\n  border-radius: 5px;\n  margin: 0 1px;\n  font-family: inherit;\n  font-size: 0.86em;\n  font-weight: 500;\n  letter-spacing: 0.3px;\n}\n", ""]);
  }, function (t, e) {
    t.exports = function (t) {
      var e = [];
      return e.toString = function () {
        return this.map(function (e) {
          var n = function (t, e) {
            var n = t[1] || "",
                r = t[3];
            if (!r) return n;

            if (e && "function" == typeof btoa) {
              var o = (s = r, "/*# sourceMappingURL=data:application/json;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(s)))) + " */"),
                  i = r.sources.map(function (t) {
                return "/*# sourceURL=" + r.sourceRoot + t + " */";
              });
              return [n].concat(i).concat([o]).join("\n");
            }

            var s;
            return [n].join("\n");
          }(e, t);

          return e[2] ? "@media " + e[2] + "{" + n + "}" : n;
        }).join("");
      }, e.i = function (t, n) {
        "string" == typeof t && (t = [[null, t, ""]]);

        for (var r = {}, o = 0; o < (this || _global).length; o++) {
          var i = (this || _global)[o][0];
          "number" == typeof i && (r[i] = !0);
        }

        for (o = 0; o < t.length; o++) {
          var s = t[o];
          "number" == typeof s[0] && r[s[0]] || (n && !s[2] ? s[2] = n : n && (s[2] = "(" + s[2] + ") and (" + n + ")"), e.push(s));
        }
      }, e;
    };
  }, function (t, e, n) {
    var r,
        o,
        i = {},
        s = (r = function () {
      return window && document && document.all && !window.atob;
    }, function () {
      return void 0 === o && (o = r.apply(this || _global, arguments)), o;
    }),
        a = function (t) {
      var e = {};
      return function (t) {
        if ("function" == typeof t) return t();

        if (void 0 === e[t]) {
          var n = function (t) {
            return document.querySelector(t);
          }.call(this || _global, t);

          if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
            n = n.contentDocument.head;
          } catch (t) {
            n = null;
          }
          e[t] = n;
        }

        return e[t];
      };
    }(),
        u = null,
        c = 0,
        f = [],
        l = n(4);

    function p(t, e) {
      for (var n = 0; n < t.length; n++) {
        var r = t[n],
            o = i[r.id];

        if (o) {
          o.refs++;

          for (var s = 0; s < o.parts.length; s++) o.parts[s](r.parts[s]);

          for (; s < r.parts.length; s++) o.parts.push(g(r.parts[s], e));
        } else {
          var a = [];

          for (s = 0; s < r.parts.length; s++) a.push(g(r.parts[s], e));

          i[r.id] = {
            id: r.id,
            refs: 1,
            parts: a
          };
        }
      }
    }

    function d(t, e) {
      for (var n = [], r = {}, o = 0; o < t.length; o++) {
        var i = t[o],
            s = e.base ? i[0] + e.base : i[0],
            a = {
          css: i[1],
          media: i[2],
          sourceMap: i[3]
        };
        r[s] ? r[s].parts.push(a) : n.push(r[s] = {
          id: s,
          parts: [a]
        });
      }

      return n;
    }

    function h(t, e) {
      var n = a(t.insertInto);
      if (!n) throw new Error("Couldn't find a style target. This probably means that the value for the 'insertInto' parameter is invalid.");
      var r = f[f.length - 1];
      if ("top" === t.insertAt) r ? r.nextSibling ? n.insertBefore(e, r.nextSibling) : n.appendChild(e) : n.insertBefore(e, n.firstChild), f.push(e);else if ("bottom" === t.insertAt) n.appendChild(e);else {
        if ("object" != typeof t.insertAt || !t.insertAt.before) throw new Error("[Style Loader]\n\n Invalid value for parameter 'insertAt' ('options.insertAt') found.\n Must be 'top', 'bottom', or Object.\n (https://github.com/webpack-contrib/style-loader#insertat)\n");
        var o = a(t.insertInto + " " + t.insertAt.before);
        n.insertBefore(e, o);
      }
    }

    function v(t) {
      if (null === t.parentNode) return !1;
      t.parentNode.removeChild(t);
      var e = f.indexOf(t);
      e >= 0 && f.splice(e, 1);
    }

    function b(t) {
      var e = document.createElement("style");
      return void 0 === t.attrs.type && (t.attrs.type = "text/css"), y(e, t.attrs), h(t, e), e;
    }

    function y(t, e) {
      Object.keys(e).forEach(function (n) {
        t.setAttribute(n, e[n]);
      });
    }

    function g(t, e) {
      var n, r, o, i;

      if (e.transform && t.css) {
        if (!(i = e.transform(t.css))) return function () {};
        t.css = i;
      }

      if (e.singleton) {
        var s = c++;
        n = u || (u = b(e)), r = x.bind(null, n, s, !1), o = x.bind(null, n, s, !0);
      } else t.sourceMap && "function" == typeof URL && "function" == typeof URL.createObjectURL && "function" == typeof URL.revokeObjectURL && "function" == typeof Blob && "function" == typeof btoa ? (n = function (t) {
        var e = document.createElement("link");
        return void 0 === t.attrs.type && (t.attrs.type = "text/css"), t.attrs.rel = "stylesheet", y(e, t.attrs), h(t, e), e;
      }(e), r = function (t, e, n) {
        var r = n.css,
            o = n.sourceMap,
            i = void 0 === e.convertToAbsoluteUrls && o;
        (e.convertToAbsoluteUrls || i) && (r = l(r));
        o && (r += "\n/*# sourceMappingURL=data:application/json;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(o)))) + " */");
        var s = new Blob([r], {
          type: "text/css"
        }),
            a = t.href;
        t.href = URL.createObjectURL(s), a && URL.revokeObjectURL(a);
      }.bind(null, n, e), o = function () {
        v(n), n.href && URL.revokeObjectURL(n.href);
      }) : (n = b(e), r = function (t, e) {
        var n = e.css,
            r = e.media;
        r && t.setAttribute("media", r);
        if (t.styleSheet) t.styleSheet.cssText = n;else {
          for (; t.firstChild;) t.removeChild(t.firstChild);

          t.appendChild(document.createTextNode(n));
        }
      }.bind(null, n), o = function () {
        v(n);
      });

      return r(t), function (e) {
        if (e) {
          if (e.css === t.css && e.media === t.media && e.sourceMap === t.sourceMap) return;
          r(t = e);
        } else o();
      };
    }

    t.exports = function (t, e) {
      if ("undefined" != typeof DEBUG && DEBUG && "object" != typeof document) throw new Error("The style-loader cannot be used in a non-browser environment");
      (e = e || {}).attrs = "object" == typeof e.attrs ? e.attrs : {}, e.singleton || "boolean" == typeof e.singleton || (e.singleton = s()), e.insertInto || (e.insertInto = "head"), e.insertAt || (e.insertAt = "bottom");
      var n = d(t, e);
      return p(n, e), function (t) {
        for (var r = [], o = 0; o < n.length; o++) {
          var s = n[o];
          (a = i[s.id]).refs--, r.push(a);
        }

        t && p(d(t, e), e);

        for (o = 0; o < r.length; o++) {
          var a;

          if (0 === (a = r[o]).refs) {
            for (var u = 0; u < a.parts.length; u++) a.parts[u]();

            delete i[a.id];
          }
        }
      };
    };

    var m,
        w = (m = [], function (t, e) {
      return m[t] = e, m.filter(Boolean).join("\n");
    });

    function x(t, e, n, r) {
      var o = n ? "" : r.css;
      if (t.styleSheet) t.styleSheet.cssText = w(e, o);else {
        var i = document.createTextNode(o),
            s = t.childNodes;
        s[e] && t.removeChild(s[e]), s.length ? t.insertBefore(i, s[e]) : t.appendChild(i);
      }
    }
  }, function (t, e) {
    t.exports = function (t) {
      var e = "undefined" != typeof window && window.location;
      if (!e) throw new Error("fixUrls requires window.location");
      if (!t || "string" != typeof t) return t;
      var n = e.protocol + "//" + e.host,
          r = n + e.pathname.replace(/\/[^\/]*$/, "/");
      return t.replace(/url\s*\(((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*)\)/gi, function (t, e) {
        var o,
            i = e.trim().replace(/^"(.*)"$/, function (t, e) {
          return e;
        }).replace(/^'(.*)'$/, function (t, e) {
          return e;
        });
        return /^(#|data:|http:\/\/|https:\/\/|file:\/\/\/|\s*$)/i.test(i) ? t : (o = 0 === i.indexOf("//") ? i : 0 === i.indexOf("/") ? n + i : r + i.replace(/^\.\//, ""), "url(" + JSON.stringify(o) + ")");
      });
    };
  }, function (t, e, n) {
    "use strict";

    n.r(e);
    n(0);

    function r(t, e) {
      for (var n = 0; n < e.length; n++) {
        var r = e[n];
        r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, r.key, r);
      }
    }

    function o(t, e, n) {
      return e && r(t.prototype, e), n && r(t, n), t;
    }

    n.d(e, "default", function () {
      return i;
    });

    var i = function () {
      function t(e) {
        var n = e.api;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global).api = n, (this || _global).button = null, (this || _global).tag = "CODE", (this || _global).iconClasses = {
          base: (this || _global).api.styles.inlineToolButton,
          active: (this || _global).api.styles.inlineToolButtonActive
        };
      }

      return o(t, null, [{
        key: "CSS",
        get: function () {
          return "inline-code";
        }
      }]), o(t, [{
        key: "render",
        value: function () {
          return (this || _global).button = document.createElement("button"), (this || _global).button.type = "button", (this || _global).button.classList.add((this || _global).iconClasses.base), (this || _global).button.innerHTML = (this || _global).toolboxIcon, (this || _global).button;
        }
      }, {
        key: "surround",
        value: function (e) {
          if (e) {
            var n = (this || _global).api.selection.findParentTag((this || _global).tag, t.CSS);

            n ? this.unwrap(n) : this.wrap(e);
          }
        }
      }, {
        key: "wrap",
        value: function (e) {
          var n = document.createElement((this || _global).tag);
          n.classList.add(t.CSS), n.appendChild(e.extractContents()), e.insertNode(n), (this || _global).api.selection.expandToTag(n);
        }
      }, {
        key: "unwrap",
        value: function (t) {
          (this || _global).api.selection.expandToTag(t);

          var e = window.getSelection(),
              n = e.getRangeAt(0),
              r = n.extractContents();
          t.parentNode.removeChild(t), n.insertNode(r), e.removeAllRanges(), e.addRange(n);
        }
      }, {
        key: "checkState",
        value: function () {
          var e = (this || _global).api.selection.findParentTag((this || _global).tag, t.CSS);

          (this || _global).button.classList.toggle((this || _global).iconClasses.active, !!e);
        }
      }, {
        key: "toolboxIcon",
        get: function () {
          return "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" viewBox=\"0 0 24 24\"><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-width=\"2\" d=\"M9.5 8L6.11524 11.8683C6.04926 11.9437 6.04926 12.0563 6.11524 12.1317L9.5 16\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-width=\"2\" d=\"M15 8L18.3848 11.8683C18.4507 11.9437 18.4507 12.0563 18.3848 12.1317L15 16\"/></svg>";
        }
      }], [{
        key: "isInline",
        get: function () {
          return !0;
        }
      }, {
        key: "sanitize",
        get: function () {
          return {
            code: {
              class: t.CSS
            }
          };
        }
      }]), t;
    }();
  }]).default;
});
export default exports;
export const InlineCode = exports.InlineCode;